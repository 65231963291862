import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { Link, redirect } from 'react-router-dom';

const schema = yup.object({
  name: yup.string().required('Name is required.'),
  subject: yup.string().required('Subject is required.'),
  email: yup.string().email('Email is not valid.').required('Email is required.'),
  message: yup.string().required('Message is required.'),
});



export default function Contact({serviceId, templateId, publicKey}) {
  const formRef = useRef();

  const form = useForm({
    defaultValues: {
      name: '',
      subject: '',
      email: '',
      message: '',
    },
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, formState, reset } = form;
  const { errors, isSubmitted } = formState;

  
  const onSubmit = async () => {
      // setFormData(data);

    await emailjs.sendForm(serviceId, templateId, formRef.current, publicKey).then(
      (result) => {
        console.log(result.text);
        reset();
        redirect('/#home')
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <section id='contact' className='contact-area bg-dark section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 section-title'>
            <h2>Get In Touch</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-10 offset-lg-1 col-md-12'>
            <p className='form-message'></p>
            <br />
            <form
              ref={formRef}
              onSubmit={handleSubmit(onSubmit)}
              className='contact-form form'
              id='contact-form'
              noValidate>
              <div className='controls'>
                <div className='row'>
                  <div className='col-lg-6 col-md-12'>
                    <div className='form-group has-error has-danger'>
                      <input id='form_name' type='text' name='name' placeholder='Name' {...register('name')} />
                      <p className='text-danger'>{errors.name?.message}</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-12'>
                    <div className='form-group has-error has-danger'>
                      <input
                        id='form_subject'
                        type='text'
                        name='subject'
                        placeholder='Subject'
                        {...register('subject')}
                      />
                      <p className='text-danger'>{errors.subject?.message}</p>
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-12'>
                    <div className='form-group has-error has-danger'>
                      <input id='form_email' type='email' name='email' placeholder='Email' {...register('email')} />
                      <p className='text-danger'>{errors.email?.message}</p>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <textarea
                        id='form_message'
                        name='message'
                        placeholder='Message'
                        
                        {...register('message')}></textarea>
                      <p className='text-danger'>{errors.message?.message}</p>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <button className='after-btn'>Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export async function action() {
  return;
}
