import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className='footer-area'>
      <div className='copyright-area text-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <p>Copyright &copy; {new Date().getFullYear()} - All Right Reserved.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Back to top */}
      <Link to="/" className="back-to-top back-to-top-pulse"><i className="fa fa-caret-up"></i></Link>
    </footer>
    
  );
}
