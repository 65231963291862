import React from 'react';
import NavItem from './NavItem';

export default function Nav() {
  return (
    <nav className='navbar navbar-b navbar-trans navbar-expand-md fixed-top' id='mainNav' style={{background: '#000'}}>
      <div className='container'>
        <a className='navbar-brand js-scroll' href='index.html'>
          <span className='color-text'>Prem</span><span className='ms-2'>Rajah</span>
        </a>
        <button
          className='navbar-toggler collapsed'
          type='button'
          data-toggle='collapse'
          data-target='#navbarDefault'
          aria-controls='navbarDefault'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className='navbar-collapse collapse justify-content-end' id='navbarDefault'>
          <ul className='navbar-nav'>
            <NavItem itemText="home" activeStatus="active" linkText={undefined} />
            <NavItem itemText="about" linkText={undefined} activeStatus={undefined}  />
            <NavItem itemText="service" linkText={undefined} activeStatus={undefined} />
            <NavItem itemText="resume" linkText={undefined} activeStatus={undefined} />
            {/* <NavItem itemText="portfolio" linkText="project" /> */}
            {/* <NavItem itemText="blog" /> */}
            <NavItem itemText="contact" linkText={undefined} activeStatus={undefined} />
          </ul>
        </div>
      </div>
    </nav>
  );
}
