import React from 'react';

function SingleServiceBox({ heading, mainText }) {
  return (
    <div className='single-services-box'>
      <div className='single-services'>
        <div className='services-icon'>
          <i className='pe-7s-target'></i>
        </div>
        <div className='services-text'>
          <h3>{heading ?? ''}</h3>
          <p>{mainText ?? ''}</p>
        </div>
      </div>
    </div>
  );
}

export default SingleServiceBox;
