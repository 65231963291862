import React from 'react';
import ScrollToHashElement from './ScrollToHashElement';

export default function Layout({ children }) {
  return (
    <>
      <ScrollToHashElement />
      <div>{children}</div>
    </>
  );
}
