import React from 'react';
import { Link } from 'react-router-dom';
import IntroAnimation from './IntroAnimation';

export default function Home() {
  return (
    <header id='home' className='home-area hero-equal-height section-padding'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-8 offset-lg-2 col-md-8 offset-md-2'>
            <div className='text-center home-content z-index position-relative  '>
              <h3>Welcome</h3>
              <h4>I am Prem <span className='color-text'>Rajah</span></h4>
              <IntroAnimation />
              <Link to='#contact' className='button home-btn-2 js-scroll'>
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='arrow'>
        <Link to='#about' className='js-scroll'>
          <i className='fa fa-chevron-down'></i>
        </Link>
      </div>
    </header>
  );
}
