import React from 'react';
import SingleServiceBox from './SingleServiceBox';
import Slider from 'react-slick';

export default function Service() {
  const reactSlickSettings = {
    className: 'services-slider',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <section id='service' className='service-area section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='section-title'>
              <h2>My Service</h2>
            </div>
          </div>
        </div>
        <div className='service-content'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <Slider {...reactSlickSettings}>
                <div>
                  <SingleServiceBox
                    heading='Software Development'
                    mainText='Creating, designing, deploying and supporting software.'
                  />
                </div>
                <div>
                  <SingleServiceBox
                    heading='Full Stack Development'
                    mainText='Design, create, test, and deploy a complete web application from start to finish.'
                  />{' '}
                </div>

                <div>
                  <SingleServiceBox
                    heading='JS, Python, C#, HTML and CSS'
                    mainText='Web and Software development using tools of the trade.'
                  />
                </div>

                <div>
                  <SingleServiceBox
                    heading='UI and UX Design'
                    mainText='Conceptualize, Create and Design high-end products.'
                  />
                </div>

                <div>
                  <SingleServiceBox
                    heading='NodeJS, ReactJS, AngularJS'
                    mainText='Use Frameworks and tools to deliver client specified products.'
                  />
                </div>

                <div>
                  <SingleServiceBox
                    heading='E-Commerce'
                    mainText='Using digital methods to sell products and services to customers.'
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
