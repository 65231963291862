import TimelineItem from './TimelineItem';
import SkillItem from './SkillItem';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Work() {
  const date = new Date();

  return (
    <section id='resume' className='work-eduction-skills-area bg-dark section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='section-title'>
              <h2>Work & Eduction</h2>
            </div>
          </div>
        </div>
        <div className='work-eduction-wrapper'>
          <div className='row'>
            <div
              className='col-md-6 wow customFadeInUp  delay-0-2s slow'
              style={{ visibility: 'visible', animationName: 'customFadeInUp' }}>
              <div className='timeline'>
                <TimelineItem
                  heading='Full Stack Developer'
                  company='Loopcycle'
                  mainText='A platform for the future; igniting a circular community of change makers to fuel creators everywhere in the design and production of circular products.'
                  yearsFrom='Oct 2020'
                  yearTo={date.getFullYear()}
                  type={undefined}
                />
                <TimelineItem
                  heading='Full Stack Developer'
                  company='Red River Software'
                  type='Contract'
                  mainText='Delivering complex software projects and building long-term strategic technical partnerships, we work in industries from aerospace to veterinary, and count household names such as Airbus, Nuffield Health and EasyJet among our clients.'
                  yearsFrom='May 2020'
                  yearTo='Aug 2020'
                />
                <TimelineItem
                  heading='Software Developer'
                  company='Creative Technology Ltd'
                  mainText='Creative Technology (CT) is one of the world’s leading suppliers of specialist Audio Visual equipment to the sports, corporate, exhibition, entertainment and permanent installation markets.'
                  yearsFrom='Mar 2017'
                  yearTo='May 2020'
                  type={undefined}
                />
              </div>
            </div>
            <div
              className='col-md-6 wow customFadeInUp delay-0-4s slow'
              style={{ visibility: 'visible', animationName: 'customFadeInUp' }}>
              <div className='timeline'>
                <TimelineItem
                  heading='Master of Science (M.Sc.) - Computing with Digital Media CS'
                  company='University of Sussex'
                  type='Part-time'
                  mainText='The University of Sussex was the first of the new wave of UK universities founded in the 1960s and we now have world-leading research across all of its schools and departments.
                  .'
                  yearsFrom='2014'
                  yearTo='2016'
                />
                <TimelineItem
                  heading='Master of Arts (M.A.) - Digital Games Design'
                  company='University for the Creative Arts'
                  mainText='A creative university defined by adaptability and innovation. Specializing in Arts, Business and Technology, we’re excited by change and difference, and the possibilities they open up.'
                  yearsFrom='2008'
                  yearTo='2009'
                  type={undefined}
                />
                <TimelineItem
                  heading='Bachelors - English Literature'
                  company='University of Madras'
                  mainText='Madras University is the mother of almost all the old Universities of southern India.'
                  yearsFrom='2001'
                  yearTo='2004'
                  type={undefined}
                />
              </div>
            </div>
          </div>

          <div>
            <Link to='https://www.linkedin.com/in/premrajah/' target='_blank' className='after-btn js-scroll'>
              View on Linkedin
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='section-title'>
              <h2>My Skills</h2>
            </div>
          </div>
        </div>
        <div id='skills' className='skills-wrapper'>
          <div className='row'>
            <div className='col-md-6'>
              <SkillItem heading='Javascript' value='96' />
              <SkillItem heading='React JS' value='92' />
              <SkillItem heading='Jquery' value='80' />
              <SkillItem heading='Angular' value='70' />
              <SkillItem heading='HTML5 and CSS3' value='94' />
            </div>
            <div className='col-md-6'>
              <SkillItem heading='Python' value='83' />
              <SkillItem heading='C Sharp' value='88' />
              <SkillItem heading='NodeJS and Express' value='81' />
              <SkillItem heading='Photoshop' value='94' />
              <SkillItem heading='Illustrator' value='92' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
