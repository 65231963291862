import React from 'react';

function TimelineItem({ heading, company, mainText, type, yearsFrom, yearTo }) {
  return (
    <div className='timeline-item'>
      <div className='icon'></div>
      <div className='box'>
        <h5>{heading ?? ''}</h5>
        <small className='bg-base-color'>{yearsFrom ?? '0000'} - {yearTo ?? '0000'}</small>
        <small>{company ?? ''}</small>
        <small>{type ?? 'Full-time'}</small>
        <p>{mainText ?? ''}</p>
      </div>
    </div>
  );
}

export default TimelineItem;
