import React from 'react';
import './App.css';
import '../node_modules//bootstrap//dist//css//bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/magnific-popup/dist/magnific-popup.css';
import '../src/assets/styles/responsive.css';
import '../src/assets/styles/themeStyles.css';
import '../src/assets/styles/IntroAnimation.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import Layout from 'components/Layout';
import Nav from 'components/Nav';
import Home from 'components/Home';
import About from 'components/About';
import Service from 'components/Service';
import Work from 'components/Work';
import Contact from 'components/Contact';
import Footer from 'components/Footer';
import { BrowserRouter as Router } from 'react-router-dom';

const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;


function App() {
    return (
        <Router>
            <Layout>
                <Nav />
                <Home />
                <About />
                <Service />
                <Work />
                <Contact serviceId={serviceId} templateId={templateId} publicKey={publicKey}  />
                <Footer />
            </Layout>
        </Router>
    );
}

export default App;
