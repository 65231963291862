import React from "react";
import { Link } from "react-router-dom";
import aboutImage from "../assets/images/about.jpg";

export default function About() {
  return (
    <section id='about' className='about-area bg-dark section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='section-title'>
              <h2>About Me</h2>
            </div>
          </div>
        </div>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className='about-img-wrapper'>
              <img className='img-fluid' src={aboutImage} alt='about' />
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className='about-content'>
              <h3>
                I am <span className='color-text'>Prem Rajah</span>
              </h3>
              <h6>Developer, Designer, Entrepreneur and Geek</h6>
              <p>
                An accomplished Senior Developer with 8+ years of experience in building, maintaining, and executing
                software from development to staging and live. Proven ability to lead and contribute to the development
                of high-quality, scalable software solutions.
              </p>

              <p>An entrepreneur who takes pride in the quality of his work and timely delivery. </p>
              <p>
                An individual who has a reputation of creativity, quality, and a strong aesthetic sense among his peers.
              </p>
              <p>
                My multifaceted experience, technical proficiency, and a history of successful project leadership make
                me a valuable asset to any development team seeking an accomplished Senior Developer. I bring a dynamic
                skill set, a commitment to excellence, and a proven ability to deliver innovative and impactful
                solutions that drive project success.
              </p>
              <p>
                <span className='color-text'>Areas of Interest</span>: ReactJS ⋅ Angular ⋅ VueJS ⋅ React Native ⋅
                Flutter ⋅ C# ⋅ .NET Core ⋅ NodeJS ⋅ Python ⋅ Web3 ⋅ HTML ⋅ CSS ⋅ SQL ⋅ NoSQL Databases ⋅ Full Stack
                Development ⋅ Software Development ⋅ E-Commerce ⋅ Sustainability Services ⋅ AI ⋅ Machine Learning
              </p>
              <Link to='https://www.linkedin.com/in/premrajah/' target='_blank' className='after-btn js-scroll'>
                View on Linkedin
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
