import React from 'react';

function SkillItem({ heading, value }) {
  return (
    <div className='skill-item'>
      {/* <h6>{heading ?? ""}</h6>
      <div className='skill-progress'>
        <div
          className='skill-progress-bar bg-base-color'
          role='progressbar'
          aria-valuenow={value ?? 0}
          aria-valuemin='0'
          aria-valuemax='100'
          style={{ width: '90%' }}>
          <span>{value ?? 0}</span>
        </div>
      </div> */}
      <label htmlFor={heading ?? 'Heading'}>
        <h6>{heading ?? ''}</h6>
      </label>
      <div className=''>
        <progress
          id={heading ?? 'Heading'}
          value={value ?? 90}
          max='100'
          style={{ 
            width: '90%', 
            height: '6px', 
            backgroundColor: 'white', 
            borderRadius: '4px',
            }}>
          {value ?? ''}
        </progress>
      </div>
    </div>
  );
}

export default SkillItem;
