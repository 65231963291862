import React from 'react';
import { Link } from 'react-router-dom';

export default function NavItem({ itemText, linkText, activeStatus }) {
  return (
    <li className='nav-item'>
      <Link className={`nav-link js-scroll text-capitalize ${activeStatus ? activeStatus : ''}`} to={`#${linkText ? linkText : itemText}`}>
        {itemText}
      </Link>
    </li>
  );
}
